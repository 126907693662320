import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function ViewMembers({ limit }) {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [search, setSearch] = useState('')
	const [data, setData] = useState([])

	const columns = [
		{
			name: 'First Name',
			selector: row => row.firstname,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: row => row.lastname,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: row => row.phone,
			sortable: false,
			group: 2,
		},
		{
			name: 'Gender',
			selector: row => row.gender,
			sortable: true,
		},
		{
			name: 'Baptism',
			selector: row => row.baptism,
			sortable: true,
		},
		{
			name: 'Zone',
			selector: row => row.zone.name,
			sortable: true,
		},
		{
			name: 'Branch',
			selector: row => row.branch.name,
			sortable: false,
		},
		{
			name: 'Role',
			selector: row => `${row.level[0]} ${row.role[0]}`,
			sortable: true,
		},
		{
			name: 'Action',
			cell: row => <Link
				to={`/members/view/${row.id}`}
				state={{ member: row }}
				className='btn btn-sm pcm-btn-primary'>
				View
			</Link>,
			sortable: false,
		}
	]

	async function fetchData() {
		try {
			const response = await fetch(`${BASE_API_URL}/users?limit=${limit ? limit : 0}`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				return
			}

			const fetchedData = await response.json()
			const members = fetchedData.users
			if (members.length === 0) {
				setMessage('No members found.')
			}
			else {
				setData(members)
			}
		}
		catch (error) {
			console.log(error);
			setMessage('Error fetching data. Try again.')
		}
	}

	const handleSearch = async (search) => {
		if (search.length === 0) {
			fetchData()
			return
		}

		if (search.length < 3 && search.length > 0) {
			return
		}

		try {
			const response = await fetch(`${BASE_API_URL}/search-user?search=${search}`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				return
			}

			const res = await response.json()
			const users = res.users
			if (users.length === 0) {
				setMessage('No members found.')
			}
			else {
				setData(users);
			}
		}
		catch (error) {
			setMessage('Error fetching data. Try again.')
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3 mb-3'>
				Members of TUCASA {auth.user.level !== "BRANCH" ? auth.user.level: auth.user.branch.name}
			</p>
			<div className='shadow rounded p-2 d-flex mb-3'>
				<input
					type="text"
					className="form-control me-1"
					placeholder="Search members (at least 4 letters)"
					value={search}
					onChange={e => {
						setSearch(e.target.value)
						handleSearch(e.target.value)
					}}
				/>
				<button className="btn btn-sm btn-success px-3" onClick={handleSearch}>
					Search
				</button>
			</div>
			<DataTable
				columns={columns}
				data={data}
				highlightOnHover
				pagination
				paginationPerPage={20}
				noDataComponent={message}
			/>
		</div>
	)
}

export default ViewMembers