import React, { useEffect, useState } from 'react'
import Banner from '../../assets/images/banner.png'
import Image from '../../assets/images/pcm.png'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function ViewEvents() {
	const auth = useAuth()
	const [events, setEvents] = useState([])

	const fetchEvents = async () => {
		try {
			const response = await fetch(`${BASE_API_URL}/events`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})
			const res = await response.json()
			setEvents(res.events)
		}
		catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchEvents()
	}, [])

	return (
		<div>
			{
				events.length > 0 && (
					<div className='banner-container'>
						<img
							src={`${BASE_API_URL}${events[0].banner}`}
							alt="Banner"
							className='img-fluid'
						/>
						<div className='banner-text'>
							{events[0].title} | {events[0].location}
							<br />
							{new Date(events[0].date).toLocaleDateString()} | {events[0].time}
						</div>
					</div>
				)
			}
			<div className='row mt-5 mx-2'>
				{
					events.length > 0 && events.slice(1).map((event) => (
						<div key={event._id} className="card col-lg-3 col-sm-12 mb-3 mx-auto">
							<img
								className="card-img-top p-1"
								src={`${BASE_API_URL}${event.banner}`}
								alt="Title"
							/>
							<div className="card-body">
								<h4 className="card-title">{event.title}</h4>
								<p className="card-text">
									{event.location} <br />
									{new Date(event.date).toLocaleDateString()} | {event.time}
								</p>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default ViewEvents