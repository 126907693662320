import './App.css';
import { Routes, Outlet } from 'react-router-dom'
import { Route } from 'react-router'
import Home from './pages/Home'
import Login from './pages/Login'
import { AuthProvider } from './context/AuthContext';
import Alert from './components/Alert';
import Dashboard from './pages/Dashboard';
import Leadership from './pages/Leadership';
import Profile from './pages/Profile';
import Events from './pages/events/Events';
import Members from './pages/Members';
import NewMember from './components/members/NewMember';
import ViewMembers from './components/members/ViewMembers';
import Transition from './components/leadership/Transition';
import Current from './components/leadership/Current';
import ViewEvents from './components/events/ViewEvents';
import NewEvent from './components/events/NewEvent';
import Contribute from './components/contributions/Contribute';
import AllContributions from './components/contributions/AllContributions';
import MyContributions from './pages/MyContributions';
import Contributions from './pages/Contributions';
import MemberList from './components/MemberList';
import MemberDetails from './components/members/MemberDetails';
import CreateContribution from './components/contributions/CreateContribution';
import ManageContributions from './components/contributions/ManageContributions';
import NewMessages from './components/messages/NewMessages';
import OldMessages from './components/messages/OldMessages';
import History from './components/leadership/History';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path='members' element={<Members />}>
            <Route path='new' element={<NewMember />} />
            <Route path='view' element={<div><Outlet /></div>}>
              <Route index element={<ViewMembers />} />
              <Route path=':id' element={<MemberDetails />} />
            </Route>
          </Route>
          <Route path='contributions' element={<Contributions />}>
            <Route index element={<MemberList to={"record"} action_term={"Record"} />} />
            <Route path='create' element={<CreateContribution />} />
            <Route path='record/:id' element={<Contribute />} />
            <Route path='view' element={<AllContributions />} />
            <Route path='manage' element={<ManageContributions />} />
            <Route path='my-contributions' element={<MyContributions />} />
          </Route>
          <Route path='leaders' element={<Leadership />}>
            <Route path='ctf' element={<h1>Leadership CTF</h1>} />
            <Route path='zone' element={<h1>Leadership Zone</h1>} />
            <Route path='branch' element={<h1>Leadership Branch</h1>} />
            <Route path='current' element={<Current />} />
            <Route path='transition' element={<Transition />} />
            <Route path='history' element={<History />} />
          </Route>
          <Route path='events' element={<Events />}>
            <Route path='new' element={<NewEvent />} />
            <Route path='view' element={<ViewEvents />} />
          </Route>
          <Route path='chairs-message' element={<Outlet />}>
            <Route path='new' element={<NewMessages />} />
            <Route path='old' element={<OldMessages />} />
          </Route>
          <Route path='profile' element={<Profile />} />
          <Route path='*' element={<Alert message="Page Not Found" variant="danger" />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Alert message="Page Not Found" variant="danger" />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
