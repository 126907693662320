import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import {
	adminMenuItems, branchChairpersonMenuItems, branchSecretaryMenuItems,
	branchTreasurerMenuItems, memberMenuItems, ctfChairpersonMenuItems,
	ctfSecretaryMenuItems, ctfTreasurerMenuItems, zoneChairpersonMenuItems,
	zoneSecretaryMenuItems, zoneTreasurerMenuItems
} from '../../utils/RoleMenuItems'

function SideBarItems() {
	const auth = useAuth()

	let menuItems = [];

	const menuItemsByLevel = {
		CTF: {
			ADMIN: adminMenuItems,
			CHAIRPERSON: ctfChairpersonMenuItems,
			ASS_CHAIRPERSON: ctfChairpersonMenuItems,
			SECRETARY: ctfSecretaryMenuItems,
			TREASURER: ctfTreasurerMenuItems,
		},
		ZONE: {
			CHAIRPERSON: zoneChairpersonMenuItems,
			ASS_CHAIRPERSON: zoneChairpersonMenuItems,
			SECRETARY: zoneSecretaryMenuItems,
			TREASURER: zoneTreasurerMenuItems,
		},
		BRANCH: {
			CHAIRPERSON: branchChairpersonMenuItems,
			ASS_CHAIRPERSON: branchChairpersonMenuItems,
			SECRETARY: branchSecretaryMenuItems,
			TREASURER: branchTreasurerMenuItems,
			MEMBER: memberMenuItems,
		},
	};

	if (auth.user.level in menuItemsByLevel) {
		const roleMenu = menuItemsByLevel[auth.user.level];
		if (auth.user.role in roleMenu) {
			menuItems = roleMenu[auth.user.role];
		}
		else {
			menuItems = menuItemsByLevel["BRANCH"]["MEMBER"]
		}
	}

	if (menuItems.length === 0) {
		return (
			<div className="nav_link">
				<i className="bi bi-stop nav_icon"></i>
				<span className="nav_name">No Menu</span>
			</div>
		);
	}

	return (
		menuItems.map((item, index) => (
			item.type === "single" ? (
				<NavLink to={item.to} key={index} className="nav_link">
					<i className={`bi bi-${item.icon} nav_icon`}></i>
					<span className="nav_name">{item.title}</span>
				</NavLink>
			) : (
				<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.title.replace(" ", "")}`} aria-expanded="false" aria-controls="collapseExample">
					<NavLink to={item.to} className='nav_link' style={{ zIndex: -1 }}>
						<i className={`bi bi-${item.icon} nav_icon`}></i>
						<span className="nav_name">
							{item.title}
						</span>
					</NavLink>
					<div className="collapse nav_sub" id={`collapse${item.title.replace(" ", "")}`}>
						{
							item.menu.map((m, i) => (
								<NavLink to={m.to} className="nav_sub_link" key={i}>
									<span className='nav_name'>
										{m.title}
									</span>
								</NavLink>
							))
						}
					</div>
				</div>
			)
		))
	)
}

export default SideBarItems