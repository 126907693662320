import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import { Chart, ArcElement } from 'chart.js'
import { Pie } from 'react-chartjs-2';
import DataTable from 'react-data-table-component';
Chart.register(ArcElement);

function AllContributions() {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [contributions, setContributions] = useState([])
	const [contribution, setContribution] = useState('')
	const [analysis, setAnalysis] = useState(null)
	const [status, setStatus] = useState('fully-paid')
	const [members, setMembers] = useState([])
	const [data, setData] = useState({
		labels: ["Fully Paid", "Partially Paid", "Not Paid"],
		datasets: [
			{
				label: "Analysis Chart",
				data: [3, 5, 2],
				backgroundColor: ["#28a745", "#ffc107", "#dc3545"],
			}
		]
	})

	const columns = [
		{
			name: 'First Name',
			selector: row => row.firstname,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: row => row.lastname,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: row => row.phone,
			sortable: false,
			grow: 2,
		},
		{
			name: 'Gender',
			selector: row => row.gender,
			sortable: true,
		},
		{
			name: 'YoS',
			selector: row => row.yos,
			sortable: true,
		},
		{
			name: 'Amount',
			selector: row => row.amount_contributed,
			sortable: true,
		},
		{
			name: 'Percent',
			selector: row => `${row.percent}%`,
			sortable: true,
		}
	]

	const fetchContributions = async () => {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/contributions`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': auth.token,
					},
				})

			const res = await response.json()

			if (res.status === "failed") {
				setMessage(res.message)
				setVariant('warning')
				return
			}

			setContributions(res.contributions)
			setMessage('')
		}
		catch (error) {
			setMessage("An error occured while fetching contributions")
			setVariant('danger')
		}
	}

	const fetchAnalysis = async (id) => {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/contribution-summary/${id}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': auth.token,
					},
				})

			const res = await response.json()

			if (res.status === "failed") {
				setMessage(res.message)
				setVariant('warning')
				return
			}

			setAnalysis(res.summary)
			setMessage('')
			setData({
				labels: ["Fully Paid", "Partially Paid", "Not Paid"],
				datasets: [
					{
						label: res.summary.contribution.name,
						data: [
							res.summary.analysis.fully_paid,
							res.summary.analysis.partially_paid,
							res.summary.analysis.not_paid
						],
						backgroundColor: ["#28a745", "#ffc107", "#dc3545"],
					}
				]
			})
		}
		catch (error) {
			setMessage("An error occured while fetching contribution analysis")
			setVariant('danger')
		}
	}

	const fetchMembers = async (id, status="fully-paid") => {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/contribution-members?contribution_id=${id}&status=${status}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': auth.token,
					},
				})

			const res = await response.json()

			if (res.status === "failed") {
				setMessage(res.message)
				setVariant('warning')
				return
			}

			setMembers(res.members)
			setStatus(status)
			setMessage('')
		}
		catch (error) {
			setMessage("An error occured while fetching contribution members")
			setVariant('danger')
		}
	}

	useEffect(() => {
		fetchContributions()
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3'>View Contributions</p>
			<div className='shadow rounded my-3 p-2'>
				<div className="mb-3">
					<label htmlFor="name" className="form-label">Contribution Name</label>
					<select
						className="form-select form-select-md"
						name="name"
						id="name"
						value={contribution}
						onChange={e => {
							setContribution(e.target.value)
							fetchAnalysis(e.target.value)
							fetchMembers(e.target.value)
						}}
						required
					>
						<option value="">Select Contribution Name</option>
						{
							contributions.map((c) => (
								<option key={c.id} value={c.id}>
									{c.name}
								</option>
							))
						}
					</select>
				</div>
			</div>
			{
				analysis && (
					<div className='shadow rounded my-3 p-2 row'>
						<p>
							Analysis of 
							<strong className='mx-1'>
								{analysis.contribution.name} 
								<span className='text-danger mx-1'>
									({analysis.contribution.status})
								</span>
							</strong>
						</p>
						<div className='col my-2'>
							<p>
								Amount required for each member: &nbsp;
								<strong>
									{analysis.contribution.amount}&nbsp;
								</strong>
								Tsh<br />

								Expected total amount: &nbsp;
								<strong>
									{analysis.contribution.amount * analysis.analysis.required_to_pay}&nbsp;
								</strong>
								Tsh<br />

								Total amount paid: &nbsp;
								<strong>
									{analysis.analysis.total_amount_paid}&nbsp;
								</strong>
								Tsh
							</p>
							<p>
								Total members: &nbsp;
								<strong>
									{analysis.analysis.required_to_pay}
								</strong>
								<br />

								Members fully paid: &nbsp;
								<strong className='bg-success py-1 px-2'>
									{analysis.analysis.fully_paid}
								</strong>
								<br />

								Members partially paid: &nbsp;
								<strong className='bg-warning py-1 px-2'>
									{analysis.analysis.partially_paid}
								</strong>
								<br />

								Members not paid: &nbsp;
								<strong className='bg-danger py-1 px-2'>
									{analysis.analysis.not_paid}
								</strong>
							</p>
						</div>
						<div className='col-lg-auto mb-3 text-center'>
							{ <Pie data={data}/> }
						</div>
					</div>
				)
			}
			<div className='shadow rounded my-3 p-2'>
				<div className='row'>
					<div className='col d-flex gap-2 mx-auto fw-bold'>
						Filters:
					</div>
					<div className='col d-flex gap-2 mx-auto'>
						Fully Paid
						<input 
							type='radio' 
							name='filter' 
							value="fully-paid"  
							checked={status === 'fully-paid'}	
							onChange={e => {
								setStatus(e.target.value)
								fetchMembers(contribution, e.target.value)
							}}
						/>
					</div>
					<div className='col d-flex gap-2 mx-auto'>
						Partially Paid
						<input 
							type='radio' 
							name='filter' 
							value="partially-paid" 
							checked={status == 'partially-paid'}
							onChange={e => {
								setStatus(e.target.value)
								fetchMembers(contribution, e.target.value)
							}}
						/>
					</div>
					<div className='col d-flex gap-2 mx-auto'>
						Not Paid
						<input 
							type='radio' 
							name='filter' 
							value="not-paid"  
							checked={status == 'not-paid'}
							onChange={e => {
								setStatus(e.target.value)
								fetchMembers(contribution, e.target.value)
							}}
						/>
					</div>
				</div>
				<hr />
				<div>
					<DataTable 
						columns={columns}
						data={members}
						highlightOnHover
						pagination
						paginationPerPage={20}
						noDataComponent={`No member has ${status.replace('-', ' ')} for this contribution`}
					/>
				</div>
			</div>
		</div>
	)
}

export default AllContributions