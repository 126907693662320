import React from 'react'
import { useAuth } from '../context/AuthContext'
import Login from './Login'
import SideBar from '../components/sidebar/SideBar'
import NavBar from '../components/navbar/NavBar'
import { Outlet } from 'react-router-dom'

function Home() {
  const auth = useAuth()

  if (!auth.isLoggedIn()) {
    return (
      <Login message={"Please Login to access this page"} />
    )
  }
  else {
    return (
      <div className='home' id='body-pd'>
        <NavBar />
        <SideBar />
        <div className="">
          <Outlet />
        </div>
      </div>
    )
  }
}

export default Home