import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Alert from '../Alert'
import { BASE_API_URL } from '../../utils/constants'
import { useLocation } from 'react-router-dom'

function MemberDetails() {
	const auth = useAuth()
	const location = useLocation()
	const member = location.state.member

	const [isLoading, setisLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')
	const [isDisabled, setIsDisabled] = useState(true)

	const [firstName, setFirstName] = useState(member? member.firstname : '')
	const [middleName, setMiddleName] = useState(member? member.middlename : '')
	const [lastName, setLastName] = useState(member? member.lastname : '')
	const [gender, setGender] = useState(member? member.gender : '')
	const [baptism, setBaptism] = useState(member? member.baptism : '')
	const [phoneNumber, setPhoneNumber] = useState(member? member.phone : '')
	const [email, setEmail] = useState(member? member.email : '')
	const [residence, setResidence] = useState(member? member.residence : '')
	const [yos, setYos] = useState(member? member.yos : '')
	const [zone, setZone] = useState(member? member.zone.id : 1)
	const [branch, setBranch] = useState(member? member.branch.id : 1)
	const [zones, setZones] = useState([])
	const [branches, setBranches] = useState([])

	const handleUpdate = async (e) => {
		e.preventDefault()
		setisLoading(true)

		const data = {
			id: member.id,
			firstname: firstName,
			middlename: middleName,
			lastname: lastName,
			gender: gender,
			baptism: baptism,
			phone: phoneNumber,
			email: email,
			residence: residence,
			yos: yos,
			role: member.role,
			level: member.level,
			password: lastName,
			branch: branch,
			zone: zone
		}

		try {
			const response = await fetch(`${BASE_API_URL}/user`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
				body: JSON.stringify(data)
			})

			const responseData = await response.json()
			console.log(responseData)

			if (responseData.status === "failed") {
				setMessage(responseData.message)
				setVariant('warning')
				setisLoading(false)
			}
			else{
				setMessage(responseData.message)
				setVariant('success')
				setisLoading(false)
			}
		}
		catch (error) {
			setMessage("An error occured while registering member")
			setVariant('danger')
			setisLoading(false)
		}
	}

	const fetchZones = async () => {
		try {
			const response = await fetch(`${BASE_API_URL}/zones`, {
				method: 'GET',
				headers: {
					'x-access-token': auth.token,
				},
			})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				return
			}

			const fetchedData = await response.json()
			const zones = fetchedData.zones
			if (zones.length === 0) {
				setMessage('No zones found.')
			}
			else {
				setZones(zones)
				setZone(member.zone.id)
			}
		}
		catch (error) {
			console.log(error);
		}
	}

	const fetchBranches = async (zone_id) => {
		try {
			const response = await fetch(`${BASE_API_URL}/zone-branches/${zone_id}`, {
				method: 'GET',
				headers: {
					'x-access-token': auth.token,
				},
			})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				return
			}

			const fetchedData = await response.json()
			const branches = fetchedData.branches
			if (branches.length === 0) {
				setMessage('No branches found.')
			}
			else {
				setBranches(branches)
				setBranch(member.branch.id)
			}
		}
		catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchZones()
		fetchBranches(member.zone.id)
	}, [])

	return (
		<div className='pt-2'>
			<div className="row">
				<p className='col h3 mb-3'>TUCASA {auth.user.branch.name} Member Details</p>
				<span className="col-auto" onClick={() => setIsDisabled(!isDisabled)}>
					{
						isDisabled ? (
							<i className='bi bi-pencil-square ms-auto me-2'></i>
						):(
							<i className='bi bi-x-square ms-auto me-2'></i>
						)
					}
				</span>
			</div>
			<div className='shadow rounded p-2'>
				{
					message && <Alert variant={variant} message={message} />
				}
				<form onSubmit={handleUpdate}>
					<p className='h4 mb-3 text-muted'>Basic Info</p>
					<div className='row'>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='firstName'>First Name</label>
								<input
									type='text'
									className='form-control'
									id='firstName'
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									disabled={isDisabled}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='middleName'>Middle Name</label>
								<input
									type='text'
									className='form-control'
									id='middleName'
									value={middleName}
									onChange={(e) => setMiddleName(e.target.value)}
									disabled={isDisabled}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='lastName'>Last Name</label>
								<input
									type='text'
									className='form-control'
									id='lastName'
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									disabled={isDisabled}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='gender'>Gender</label>
								<select
									className='form-select'
									id='gender'
									value={gender}
									onChange={(e) => setGender(e.target.value)}
									disabled={isDisabled}
								>
									<option value="Female">Female</option>
									<option value="Male">Male</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='baptism'>Baptisim</label>
								<select
									className='form-select'
									id='baptism'
									value={baptism}
									onChange={(e) => setBaptism(e.target.value)}
									disabled={isDisabled}
								>
									<option value="">Select Baptism Status</option>
									<option value="Baptised">Baptised</option>
									<option value="Not Baptised">Not Baptised</option>
								</select>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Contact Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='phone'>Phone Number</label>
								<input
									type='text'
									className='form-control'
									id='phone'
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
									disabled={isDisabled}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='email'>Email Address</label>
								<input
									type='email'
									className='form-control'
									id='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									disabled={isDisabled}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='residence'>Residence</label>
								<input
									type='text'
									className='form-control'
									id='residence'
									value={residence}
									onChange={(e) => setResidence(e.target.value)}
									disabled={isDisabled}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Academic Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='yos'>Year of Study</label>
								<select
									className='form-select'
									id='yos'
									value={yos}
									onChange={(e) => setYos(e.target.value)}
									disabled={isDisabled}
								>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Additional Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='zone'>Zone</label>
								<select
									className='form-select'
									id='zone'
									value={zone}
									onChange={(e) => {
										setZone(e.target.value)
										fetchBranches(e.target.value)
									}}
									disabled={isDisabled}
									required
								>
									{
										zones.map((zone) => (
											<option key={zone.id} value={zone.id}>{zone.name}</option>
										))
									}
								</select>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='branch'>Branch</label>
								<select
									className='form-select'
									id='branch'
									value={branch}
									onChange={(e) => setBranch(e.target.value)}
									disabled={isDisabled}
									required
								>
									{
										branches.map((branch) => (
											<option key={branch.id} value={branch.id}>{branch.name}</option>
										))
									}
								</select>
							</div>
						</div>
					</div>
					{
						! isDisabled && (
							<button type='submit' className='btn pcm-btn-primary px-4'>
								{
									isLoading ? "Updating..." : "Update"
								}
							</button>
						)
					}
				</form>
			</div>
		</div>
	)
}

export default MemberDetails