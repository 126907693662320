const adminMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members",
		icon: "person-fill",
		title: "Members",
		type: "single"
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "leaders/ctf",
				title: "Leadership CTF"
			},
			{
				to: "leaders/zone",
				title: "Leadership Zone"
			},
			{
				to: "leaders/branch",
				title: "Leadership Branch"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	}
]

const branchChairpersonMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members/view",
		icon: "person-fill",
		title: "Membership",
		type: "single"
	},
	{
		to: "/contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/contributions/view",
				title: "View Contributions"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/transition",
				title: "Transition"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
]

const branchSecretaryMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members",
		icon: "person-fill",
		title: "Membership",
		type: "multiple",
		menu: [
			{
				to: "/members/new",
				title: "Register New"
			},
			{
				to: "/members/view",
				title: "View Existing"
			}
		]
	},
	{
		to: "/contributions/my-contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "single"
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events",
		icon: "calendar-fill",
		title: "Events",
		type: "multiple",
		menu: [
			{
				to: "/events/new",
				title: "New Event"
			},
			{
				to: "/events/view",
				title: "View Events"
			}
		]
	},
]

const branchTreasurerMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members/view",
		icon: "person-fill",
		title: "Membership",
		type: "single"
	},
	{
		to: "/contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/contributions/create",
				title: "Create Contribution"
			},
			{
				to: "/contributions/",
				title: "Record Contribution"
			},
			{
				to: "/contributions/view",
				title: "View Contributions"
			},
			{
				to: "/contributions/manage",
				title: "Manage Contributions"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
]

const memberMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/contributions/my-contributions",
		icon: "person-fill-up",
		title: "My Contributions",
		type: "single"
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
]

const zoneChairpersonMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members/view",
		icon: "person-fill",
		title: "Membership",
		type: "single"
	},
	{
		to: "/contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/contributions/view",
				title: "View Contributions"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/transition",
				title: "Transition"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
]

const zoneSecretaryMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members",
		icon: "person-fill",
		title: "Membership",
		type: "multiple",
		menu: [
			{
				to: "/members/new",
				title: "Register New"
			},
			{
				to: "/members/view",
				title: "View Existing"
			}
		]
	},
	{
		to: "/contributions/my-contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "single"
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events",
		icon: "calendar-fill",
		title: "Events",
		type: "multiple",
		menu: [
			{
				to: "/events/new",
				title: "New Event"
			},
			{
				to: "/events/view",
				title: "View Events"
			}
		]
	},
]

const zoneTreasurerMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members/view",
		icon: "person-fill",
		title: "Membership",
		type: "single"
	},
	{
		to: "/contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/contributions/create",
				title: "Create Contribution"
			},
			{
				to: "/contributions/",
				title: "Record Contribution"
			},
			{
				to: "/contributions/view",
				title: "View Contributions"
			},
			{
				to: "/contributions/manage",
				title: "Manage Contributions"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
]

const ctfChairpersonMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members/view",
		icon: "person-fill",
		title: "Membership",
		type: "single"
	},
	{
		to: "/contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/contributions/view",
				title: "View Contributions"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/transition",
				title: "Transition"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
	{
		to: "/chairs-message",
		icon: "chat-left-quote-fill",
		title: "Daily Messages",
		type: "multiple",
		menu: [
			{
				to: "/chairs-message/new",
				title: "New Message"
			},
			{
				to: "/chairs-message/old",
				title: "Old Messages"
			}
		]
	}
]

const ctfSecretaryMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members",
		icon: "person-fill",
		title: "Membership",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/members/new",
				title: "Register New"
			},
			{
				to: "/members/view",
				title: "View Existing"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events",
		icon: "calendar-fill",
		title: "Events",
		type: "multiple",
		menu: [
			{
				to: "/events/new",
				title: "New Event"
			},
			{
				to: "/events/view",
				title: "View Events"
			}
		]
	},
]

const ctfTreasurerMenuItems = [
	{
		to: "/",
		icon: "grid",
		title: "Dashboard",
		type: "single"
	},
	{
		to: "/members/view",
		icon: "person-fill",
		title: "Membership",
		type: "single"
	},
	{
		to: "/contributions",
		icon: "person-fill-up",
		title: "Contributions",
		type: "multiple",
		menu: [
			{
				to: "/contributions/my-contributions",
				title: "My Contributions"
			},
			{
				to: "/contributions/create",
				title: "Create Contribution"
			},
			{
				to: "/contributions/",
				title: "Record Contribution"
			},
			{
				to: "/contributions/view",
				title: "View Contributions"
			},
			{
				to: "/contributions/manage",
				title: "Manage Contributions"
			}
		]
	},
	{
		to: "/leaders",
		icon: "person-vcard-fill",
		title: "Leadership",
		type: "multiple",
		menu: [
			{
				to: "/leaders/current",
				title: "Current Leaders"
			},
			{
				to: "/leaders/history",
				title: "Leadership History"
			}
		]
	},
	{
		to: "/events/view",
		icon: "calendar-fill",
		title: "Events",
		type: "single"
	},
]

export { 
	adminMenuItems, 
	branchChairpersonMenuItems,
	branchSecretaryMenuItems,
	branchTreasurerMenuItems,
	memberMenuItems,
	zoneChairpersonMenuItems,
	zoneSecretaryMenuItems,
	zoneTreasurerMenuItems,
	ctfChairpersonMenuItems,
	ctfSecretaryMenuItems,
	ctfTreasurerMenuItems
}
