import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Alert from '../Alert'

function OldMessages() {
    const auth = useAuth()
    const [messages, setMessages] = useState([])

    const fetchMessages = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/chairs-message`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token,
                },
            })

            const responseData = await response.json()
            if (responseData.status === "success") {
                setMessages(responseData.messages)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMessages()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 mb-3'>Old Messages</p>
            {
                messages.length === 0 && <Alert variant='warning' message='No messages found' />
            }
            {
                messages.map(message => (
                    <div className='shadow rounded p-2 mb-3'>
                        <p>{message.body}</p>
                        <div className='row'>
                            <div className='col-sm'>
                                - CTF Chairperson <i>{message.author}</i>
                            </div>
                            <div className='col-sm text-lg-end mt-1'>
                                <small>{message.date_posted}</small>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default OldMessages
