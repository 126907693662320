import React from 'react'
import { Outlet } from 'react-router-dom'
import LeaderImage from '../assets/images/leader.jpg'

function Leadership() {
  return (
    <Outlet />
  )
}

export default Leadership