import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Alert from '../Alert'
import { BASE_API_URL } from '../../utils/constants'

function Current() {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [ctfLeaders, setCtfLeaders] = useState([])
	const [zoneLeaders, setZoneLeaders] = useState([])
	const [branchLeaders, setBranchLeaders] = useState([])

	const fetchLeaders = async () => {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/current-leaders`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})

			if (!response.ok) {
				setMessage('Error fetching leaders. Try again.')
				setVariant('warning')
				return
			}

			const res = await response.json()
			setCtfLeaders(res.ctf_leaders)
			setZoneLeaders(res.zone_leaders)
			setBranchLeaders(res.branch_leaders)
			setMessage('')
		}
		catch (error) {
			console.log(error);
			setMessage('Error fetching leaders. Try again.')
			setVariant('danger')
		}
	}

	useEffect(() => {
		fetchLeaders()
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3'>Leadership</p>
			{
				message && <Alert message={message} variant={variant} />
			}
			<div className='shadow rounded my-3 p-2'>
				<p className='h4'>CTF Leaders</p>
				<div className='row'>
					{
						ctfLeaders.map((leader, index) => {
							return (
								<div key={index} className='col-lg-3 col-sm-12 mx-auto mb-3'>
									<div className="card">
										<div className="card-body">
											<div className='text-center'>
												{
													leader.image ? (
														<img 
															className="m-1 rounded-circle" 
															width={200} 
															height={200} 
															src={`${BASE_API_URL}${leader.image}`}
															alt="Title" 
														/>

													):(
														<i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
													)
												}
											</div>
											<h4 className="card-title">
												{leader.firstname} {leader.lastname}
											</h4>
											<p className="card-text">
												{leader.role}<br />
												{leader.phone}
											</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className='shadow rounded my-3 p-2'>
				<p className='h4'>Zone Leaders</p>
				<div className='row'>
					{
						zoneLeaders.map((leader, index) => {
							return (
								<div key={index} className='col-lg-3 col-sm-12 mx-auto mb-3'>
									<div className="card">
										<div className="card-body">
											<div className='text-center'>
											{
													leader.image ? (
														<img 
															className="m-1 rounded-circle" 
															width={200} 
															height={200} 
															src={`${BASE_API_URL}${leader.image}`}
															alt="Title" 
														/>

													):(
														<i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
													)
												}
											</div>
											<h4 className="card-title">
												{leader.firstname} {leader.lastname}
											</h4>
											<p className="card-text">
												{leader.role}<br />
												{leader.phone}
											</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className='shadow rounded my-3 p-2'>
				<p className='h4'>Branch Leaders</p>
				<div className='row'>
					{
						branchLeaders.map((leader, index) => {
							return (
								<div key={index} className='col-lg-3 col-sm-12 mx-auto mb-3'>
									<div className="card">
										<div className="card-body">
											<div className='text-center mb-3'>
											{
													leader.image ? (
														<img 
															className="rounded-circle" 
															width={200} 
															height={200} 
															src={`${BASE_API_URL}${leader.image}`}
															alt="Title" 
														/>

													):(
														<i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
													)
												}
											</div>
											<h4 className="card-title">
												{leader.firstname} {leader.lastname}
											</h4>
											<p className="card-text">
												{leader.role}<br />
												{leader.phone}
											</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default Current