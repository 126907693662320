import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Alert from '../Alert'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function Contribute() {
	const auth = useAuth()
	const location = useLocation()
	const member = location.state.member

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')
	const [isLoading, setIsLoading] = useState(false)

	const [contributions, setContributions] = useState([])
	const [contribution, setContribution] = useState('')
	const [amount, setAmount] = useState('')

	const fetchContributions = async () => {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/contributions`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': auth.token,
					},
				})

			const res = await response.json()

			if (res.status === "failed") {
				setMessage(res.message)
				setVariant('warning')
				return
			}
			
			setContributions(res.contributions)
			setMessage('')			
		}
		catch(error) {
			setMessage("An error occured while fetching contributions")
			setVariant('danger')
		}
	}

	const handleSave = async (e) => {
		e.preventDefault()
		setIsLoading(true)

		const data = {
			"user_id": member.id,
			"contribution_id": contribution,
			"amount": amount
		}

		try{
			const response = await fetch(`${BASE_API_URL}/member-contribution`,{
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
				body: JSON.stringify(data)
			})
			
			const res = await response.json()
			if (res.status !== "success"){
				setIsLoading(false)
				setMessage(res.message)
				setVariant("warning")
				return
			}

			setMessage(res.message)
			setVariant("success")
			setIsLoading(false)

		}
		catch(error){
			setIsLoading(false)
			setMessage("Something went wrong! Please try again.")
			setVariant("danger")
		}
	}

	useEffect(()=>{
		fetchContributions()
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3'>Record Member Contributions</p>
			<div className='shadow rounded my-3 p-2'>
				<span className='me-3'>
					Name: <span className='fw-bold'>
						{member.firstname} {member.lastname}
					</span>
				</span>
				<span>
					Phone: <span className='fw-bold'>{member.phone}</span>
				</span>
			</div>
			{
				message && <Alert variant={variant} message={message} />
			}
			<div className='shadow rounded my-3 p-2'>
				<form onSubmit={handleSave}>
					<div className="mb-3">
						<label htmlFor="name" className="form-label">Contribution Name</label>
						<select 
							className="form-select form-select-md" 
							name="name" 
							id="name"
							value={contribution}
							onChange={e => setContribution(e.target.value)}
							required
						>
							<option value="">Select Contribution Name</option>
							{
								contributions.map((c) => (
									<option key={c.id} value={c.id}>
										{c.name}
									</option>
								))
							}
						</select>
						<small className="form-text text-muted">
							Contribution Amount: {
								contributions.filter(ct => ct.id === Number(contribution))
								.map((cr) => cr.amount)
							}
						</small>
					</div>
					<div className="mb-3">
						<label htmlFor="amount" className="form-label">Amount</label>
						<input 
							type="text" 
							className="form-control" 
							name="amount" 
							id="amount" 
							placeholder="10000.00" 
							value={amount}
							onChange={e => setAmount(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<button type="submit" className="btn pcm-btn-primary px-4">
							{
								isLoading? "Saving...":"Save"
							}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Contribute