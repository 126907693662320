import React, { useEffect, useState } from 'react'
import 'react-datalist-input/dist/styles.css'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Alert from '../Alert'
import DataList from './DataList'

function Transition() {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [members, setMembers] = useState([])
	const [membersInUse, setMembersInUse] = useState([])
	const [chairperson, setChairperson] = useState('')
	const [secretary, setSecretary] = useState('')
	const [treasurer, setTreasurer] = useState('')
	const [assChairperson, setAssChairperson] = useState('')
	const [admin, setAdmin] = useState('')

	async function fetchMembers() {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/users?limit=0`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				setVariant('warning')
				return
			}

			const res = await response.json()
			setMembers(res.users)
			setMembersInUse(res.users)
			setMessage('')
		}
		catch (error) {
			console.log(error);
			setMessage('Error fetching data. Try again.')
			setVariant('danger')
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault()

		if (chairperson === '' && secretary === '' && treasurer === '' && assChairperson === '') {
			setMessage('Please select at least one leader')
			setVariant('warning')
			return
		}

		setMessage('Loading...')
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/leadership-transition`,
				{
					method: 'POST',
					headers: {
						'x-access-token': auth.token,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						chairperson,
						secretary,
						treasurer,
						assChairperson,
					}),
				})

			if (!response.ok) {
				setMessage('Error submitting data. Try again.')
				setVariant('warning')
				return
			}

			const res = await response.json()
			setMessage(res.message)
			setVariant('success')
		}
		catch (error) {
			console.log(error);
			setMessage('Error submitting data. Try again.')
			setVariant('danger')
		}

	}

	useEffect(() => {
		fetchMembers()
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3'>Leadership Transition</p>
			<div className='shadow rounded my-3 p-2'>
				<p className='h4'>New {auth.user.level} Leaders</p>
				<form onSubmit={handleSubmit}>
					{
						message && <Alert message={message} variant={variant} />
					}
					<div className="mb-3">
						<DataList
							placeholder={"Chairperson"}
							members={members}
							membersInUse={membersInUse}
							setMembersInUse={setMembersInUse}
							setLeader={setChairperson}
							other={[secretary, treasurer, assChairperson, admin]}
						/>
					</div>
					<div className="mb-3">
						<DataList
							placeholder={"Assistant Chairperson"}
							members={members}
							membersInUse={membersInUse}
							setMembersInUse={setMembersInUse}
							setLeader={setAssChairperson}
							other={[chairperson, secretary, treasurer, admin]}
						/>
					</div>
					<div className="mb-3">
						<DataList
							placeholder={"Secretary"}
							members={members}
							membersInUse={membersInUse}
							setMembersInUse={setMembersInUse}
							setLeader={setSecretary}
							other={[chairperson, treasurer, assChairperson, admin]}
						/>
					</div>
					<div className="mb-3">
						<DataList
							placeholder={"Treasurer"}
							members={members}
							membersInUse={membersInUse}
							setMembersInUse={setMembersInUse}
							setLeader={setTreasurer}
							other={[chairperson, secretary, assChairperson, admin]}
						/>
					</div>
					{
						auth.user.level === "CTF" && (
							<div className="mb-3">
								<DataList
									placeholder={"Admin"}
									members={members}
									membersInUse={membersInUse}
									setMembersInUse={setMembersInUse}
									setLeader={setAdmin}
									other={[chairperson, secretary, assChairperson, treasurer]}
								/>
							</div>
						)
					}
					<div className="mb-3">
						<button type="submit" className="btn pcm-btn-primary px-4">Submit</button>
					</div>
					<div className="text-danger fw-bold my-3">
						Once transition is done, you wont be able to login as a leader if you are not one of the selected!
					</div>
				</form>
			</div>
		</div>
	)
}

export default Transition
