import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Alert from '../Alert'
import { BASE_API_URL } from '../../utils/constants'

function CreateContribution() {
    const auth = useAuth()

    const [isLoading, setisLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [description, setDescription] = useState('')

    const handleSave = async (e) => {
        e.preventDefault()
        setisLoading(true)

        try{
            const data = {
                name: name,
                amount: amount,
                description: description,
            }

            const response = await fetch(`${BASE_API_URL}/contribution`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token,
                },
                body: JSON.stringify(data)
            })

            const responseData = await response.json()
            console.log(responseData)

            if (responseData.status === "failed") {
                setMessage(responseData.message)
                setVariant('warning')
                setisLoading(false)
            }
            else{
                setMessage(responseData.message)
                setVariant('success')
                setisLoading(false)
            }
        }
        catch(error){
            setisLoading(false)
            setMessage("An error occured while creating the contribution")
            setVariant('danger')
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3'>Create Contribution</p>
            <div className='shadow rounded my-3 p-2'>
                {
                    message && <Alert variant={variant} message={message} />
                }
                <form onSubmit={handleSave}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Contribution Name</label>
                        <input 
                            type='text' 
                            className='form-control' 
                            name='name' 
                            id='name' 
                            placeholder='Contribution Name'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="amount" className="form-label">Amount</label>
                        <input
                            type='text'
                            className='form-control'
                            name='amount'
                            id='amount'
                            placeholder='10000.00'
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            required 
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="amount" className="form-label">Description</label>
                        <textarea 
                            className="form-control" 
                            name="description" 
                            id="description" 
                            rows="3" 
                            placeholder='A short description of the contribution'
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            required>
                        </textarea>
                    </div>
                    <div className="mb-3">
                        <button type="submit" className="btn pcm-btn-primary px-4">
                            {
                                isLoading ? 'Creating...' : 'Create'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateContribution