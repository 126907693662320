import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Alert from '../Alert'
import LeaderImage from '../../assets/images/leader.jpg'
import { BASE_API_URL } from '../../utils/constants'

function History() {
    const auth = useAuth()

    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [ctfLeaders, setCtfLeaders] = useState([])
    const [zoneLeaders, setZoneLeaders] = useState([])
    const [branchLeaders, setBranchLeaders] = useState([])
    const [years, setYears] = useState([])
    const [selectedYear, setSelectedYear] = useState(`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`)

    const fetchYears = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/leadership-history/years`,
                {
                    method: 'GET',
                    headers: {
                        'x-access-token': auth.token,
                    },
                })

            if (!response.ok) {
                setMessage('Error fetching years. Try again.')
                setVariant('warning')
                return
            }

            const res = await response.json()
            setYears(res.years)
        }
        catch (error) {
            console.log(error);
            setMessage('Error fetching years. Try again.')
            setVariant('danger')
        }
    }

    const fetchLeaders = async (year) => {
        setMessage("Loading...")
        setVariant('info')

        try {
            const response = await fetch(`${BASE_API_URL}/leadership-history/${year}`,
                {
                    method: 'GET',
                    headers: {
                        'x-access-token': auth.token,
                    },
                })

            if (!response.ok) {
                setMessage('Error fetching leaders. Try again.')
                setVariant('warning')
                return
            }

            const res = await response.json()
            setCtfLeaders(res.ctf_leaders)
            setZoneLeaders(res.zone_leaders)
            setBranchLeaders(res.branch_leaders)
            setMessage('')
            setSelectedYear(year)
        }
        catch (error) {
            console.log(error);
            setMessage('Error fetching leaders. Try again.')
            setVariant('danger')
        }
    }

    useEffect(() => {
        fetchLeaders(selectedYear)
    }, [selectedYear])

    useEffect(() => {
        fetchYears()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3'>Leadership History</p>
            <div className='shadow rounded row my-3 p-2 mx-2'>
                {
                    years.map((year, index) => {
                        return (
                            <div
                                key={index}
                                className={`col-auto mx-2 mb-1 py-2 rounded cursor-pointer 
                                    ${selectedYear === year ? 'bg-primary text-white' : 'bg-light'}}`}
                                onClick={() => {
                                    setSelectedYear(year)
                                }}
                            >
                                {year}
                            </div>
                        )
                    })
                }
            </div>
            {
                message && <Alert message={message} variant={variant} />
            }
            <div className='shadow rounded my-3 p-2'>
                <p className='h4'>CTF Leaders</p>
                <div className='row'>
                    {
                        ctfLeaders.map((leader, index) => {
                            return (
                                <div key={index} className='col-lg-3 col-sm-12 mx-auto mb-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='text-center mb-3'>
                                                {
                                                    leader.image ? (
                                                        <img
                                                            className="rounded-circle"
                                                            width={200}
                                                            height={200}
                                                            src={`${BASE_API_URL}${leader.image}`}
                                                            alt="Title"
                                                        />

                                                    ) : (
                                                        <i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
                                                    )
                                                }
                                            </div>
                                            <h4 className="card-title">
                                                {leader.firstname} {leader.lastname}
                                            </h4>
                                            <p className="card-text">
                                                {leader.role}<br />
                                                {leader.phone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='shadow rounded my-3 p-2'>
                <p className='h4'>Zone Leaders</p>
                <div className='row'>
                    {
                        zoneLeaders.map((leader, index) => {
                            return (
                                <div key={index} className='col-lg-3 col-sm-12 mx-auto mb-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='text-center mb-3'>
                                                {
                                                    leader.image ? (
                                                        <img
                                                            className="rounded-circle"
                                                            width={200}
                                                            height={200}
                                                            src={`${BASE_API_URL}${leader.image}`}
                                                            alt="Title"
                                                        />

                                                    ) : (
                                                        <i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
                                                    )
                                                }
                                            </div>
                                            <h4 className="card-title">
                                                {leader.firstname} {leader.lastname}
                                            </h4>
                                            <p className="card-text">
                                                {leader.role}<br />
                                                {leader.phone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='shadow rounded my-3 p-2'>
                <p className='h4'>Branch Leaders</p>
                <div className='row'>
                    {
                        branchLeaders.map((leader, index) => {
                            return (
                                <div key={index} className='col-lg-3 col-sm-12 mx-auto mb-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='text-center mb-3'>
                                                {
                                                    leader.image ? (
                                                        <img
                                                            className="rounded-circle"
                                                            width={200}
                                                            height={200}
                                                            src={`${BASE_API_URL}${leader.image}`}
                                                            alt="Title"
                                                        />

                                                    ) : (
                                                        <i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
                                                    )
                                                }
                                            </div>
                                            <h4 className="card-title">
                                                {leader.firstname} {leader.lastname}
                                            </h4>
                                            <p className="card-text">
                                                {leader.role}<br />
                                                {leader.phone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default History
