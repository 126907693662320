import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Alert from '../Alert'

function NewEvent() {
	const auth = useAuth()

	const [error, setError] = useState('')
	const [variant, setVariant] = useState('success')
	const [loading, setLoading] = useState(false)

	const [title, setTitle] = useState('')
	const [location, setLocation] = useState('')
	const [date, setDate] = useState('')
	const [time, setTime] = useState('')
	const [banner, setBanner] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		setError('')

		const payload = new FormData()
		payload.append('title', title)
		payload.append('location', location)
		payload.append('date', date)
		payload.append('time', time)
		payload.append('banner', banner)

		try {
			const response = await fetch(`${BASE_API_URL}/event`,
				{
					method: 'POST',
					headers: {
						'x-access-token': auth.token,
					},
					body: payload,
				})

			const res = await response.json()
			if (res.status !== 'success') {
				setError(res.message)
				setVariant('warning')
				return
			}

			setError(res.message)
			setVariant('success')
		}
		catch (err) {
			setError("Failed to create event. Please try again.")
			setVariant('danger')
			console.log(err)
		}
		finally {
			setLoading(false)
		}
	}

	return (
		<div className='pt-2'>
			<p className='h3'>New Event</p>
			{
				error && <Alert message={error} variant={variant} />
			}
			<div className='shadow rounded my-3 p-2'>
				<form onSubmit={handleSubmit}>
					<div className="mb-3">
						<label htmlFor="title" className="form-label">Event Title</label>
						<input 
							type="text"
							className="form-control" 
							name="title" 
							id="title" 
							placeholder="Title of the Event" 
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="location" className="form-label">Event Location</label>
						<input 
							type="text"
							className="form-control" 
							name="location" 
							id="location" 
							placeholder="Location of the Event" 
							value={location}
							onChange={(e) => setLocation(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="date" className="form-label">Event Date</label>
						<input 
							type="date"
							className="form-control" 
							name="date" 
							id="date" 
							placeholder="mm/dd/yyyy" 
							value={date}
							onChange={(e) => setDate(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="time" className="form-label">Event Date</label>
						<input 
							type="time"
							className="form-control" 
							name="time" 
							id="time" 
							placeholder="15:00" 
							value={time}
							onChange={(e) => setTime(e.target.value)}
							required
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="banner" className="form-label">Event Banner</label>
						<input 
							type="file"
							className="form-control" 
							name="banner" 
							id="banner" 
							placeholder="image file" 
							onChange={(e) => setBanner(e.target.files[0])}
							required
						/>
					</div>
					<div className="mb-3">
						<button type="submit" className="btn pcm-btn-primary px-4">Save</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default NewEvent