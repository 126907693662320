import React from 'react'
import './Events.css'
import { Outlet } from 'react-router-dom'

function Events() {
	return (
		<Outlet />
  )
}

export default Events
