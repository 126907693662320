import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { BASE_API_URL } from '../utils/constants'
import DataTable from 'react-data-table-component'
import Alert from './Alert'

function MemberList({ limit, to, action_term }) {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')
	const [members, setMembers] = useState([])

	const columns = [
		{
			name: 'First Name',
			selector: row => row.firstname,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: row => row.lastname,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: row => row.phone,
			sortable: false,
			grow: 2,
		},
		{
			name: 'Gender',
			selector: row => row.gender,
			sortable: true,
		},
		{
			name: 'YoS',
			selector: row => row.yos,
			sortable: true,
		},
		{
			name: 'Action',
			cell: row => <Link
				to={`${to}/${row.id}`}
				state={{ member: row }}
				className='btn btn-sm pcm-btn-primary'>
				{action_term}
			</Link>,
			sortable: false,
		}
	]

	async function fetchData() {
		setMessage("Loading...")
		setVariant('info')

		try {
			const response = await fetch(`${BASE_API_URL}/users?limit=${limit ? limit : 0}`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				setVariant('warning')
				return
			}

			const res = await response.json()
			setMembers(res.users)
			setMessage('')
		}
		catch (error) {
			console.log(error);
			setMessage('Error fetching data. Try again.')
			setVariant('danger')
		}
	}

	const handleSearch = async (search) => {
		if (search.length === 0) {
			fetchData()
			return
		}

		if (search.length < 3 && search.length > 0) {
			return
		}

		try {
			const response = await fetch(`${BASE_API_URL}/search-user?search=${search}`,
				{
					method: 'GET',
					headers: {
						'x-access-token': auth.token,
					},
				})

			if (!response.ok) {
				setMessage('Error fetching data. Try again.')
				setVariant('warning')
				return
			}

			const res = await response.json()
			const users = res.users
			if (users.length === 0) {
				setMessage('No members found.')
				setVariant('info')
			}
			else {
				setMembers(users);
			}
		}
		catch (error) {
			setMessage('Error fetching data. Try again.')
			setVariant('danger')
		}
	}

	useEffect(()=>{
		fetchData()
	},[])

	return (
		<div className='pt-2'>
			<p className='h3'>Select Member</p>
			<div className="body shadow rounded p-2 mb-3 d-flex">
				<input
					type="text"
					className="form-control me-1"
					placeholder="Search members (at least 4 characters)"
					onChange={e => handleSearch(e.target.value)}
				/>
				<button className="btn btn-sm btn-success px-3">
					Search
				</button>
			</div>
			{
				message && <Alert variant={variant} message={message} />
			}
			<div>
				<DataTable
					columns={columns}
					data={members}
					highlightOnHover
					pagination
					paginationPerPage={20}
					noDataComponent={message}
				/>
			</div>
		</div>
	)
}

export default MemberList