import React from 'react'
import './SideBar.css'
import logo from '../../assets/images/pcm-small.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import SideBarItems from './SideBarItems'

function SideBar() {
  const navigate = useNavigate()
  const auth = useAuth()

  const logout = () => {
    auth.logout()
    navigate('/login')
  }

  return (
    <>
      <div className="l-navbar" id="nav-bar">
        <nav className="nav">
          <div>
            <a href="https://tucasa.com" className="nav_logo">
              <img className='img nav_logo-img' src={logo} alt="logo" />
              <span className="nav_logo-name">TUCASA-CTF IMS</span>
            </a>
            <div className="nav_list">
              <SideBarItems />
            </div>
          </div>
          <span className="nav_link cursor" onClick={logout}>
            <i className='bi bi-box-arrow-left nav_icon'></i>
            <span className="nav_name">Logout</span>
          </span>
        </nav>
      </div>
    </>
  )
}

export default SideBar