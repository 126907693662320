import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/images/pcm.png'
import logo_small from '../assets/images/pcm-small.png'
import Alert from '../components/Alert'
import { useAuth } from '../context/AuthContext'
import { BASE_API_URL } from '../utils/constants'

function Login({ message }) {
  const navigate = useNavigate()
  const auth = useAuth()

  const [errorMessage, setErrorMessage] = useState(message)
  const [variant, setVariant] = useState('danger')
  const [isLoading, setIsLoading] = useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setErrorMessage('')

    let credentials = {
      username: username,
      password: password
    }

    try {
      const response = await fetch(`${BASE_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      });

      if (response.ok) {
        let data = await response.json()
        const token = data.access_token
        auth.login(token)
        navigate("/")
      }
      else {
        setErrorMessage("Incorrect Username or Password")
        setVariant('danger')
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.')
      setVariant('danger')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    // if user is logged in redirect to dashboard
    if (auth.isLoggedIn()) {
      navigate("/")
      return
    }
  }, [])

  return (
    <div className='container mt-5'>
      <h1 className='h1 text-center'>TUCASA-CTF </h1>
      <h3 className='h3 text-center mb-4'>INFORMATION MANAGEMENT SYSTEM</h3>
      <div className='row justify-content-center align-items-center gap-5'>
        <div className='col-lg-3 col-sm-12 text-center'>
          <picture>
            <source srcSet={logo} media='(min-width: 768px)' />
            <img src={logo_small} alt='PCM logo' className='img-fluid' />
          </picture>
        </div>
        <div className='col-lg-5 col-sm-12 border-start ps-lg-5'>
          <span className='fs-3 text-muted mb-1'>Login</span>
          {
            errorMessage && <Alert message={errorMessage} variant={variant} />
          }
          <form className='shadow rounded px-3 pb-3 fs-5' onSubmit={handleLogin}>
            <div className='mb-3'>
              <label htmlFor='username' className='form-label'>
                Username
              </label>
              <input 
                type='text' 
                className='form-control' 
                id='username' 
                placeholder='Username'
                required
                value={username}
                onChange={e => {
                  setErrorMessage('')
                  setUsername(e.target.value)
                }}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='password' className='form-label'>
                Password
              </label>
              <input 
                type='password' 
                className='form-control' 
                id='password' 
                placeholder='password'
                required
                value={password}
                onChange={e => {
                  setErrorMessage('')
                  setPassword(e.target.value)
                }}
              />
            </div>
            <div className='text-center'>
              <button type='submit' className='btn pcm-btn-primary px-4 fw-bold'>
                {
                  isLoading ? 'Loging in...' : 'Login'
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login