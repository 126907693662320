import React from 'react'
import DatalistInput from 'react-datalist-input'

function DataList({
    placeholder,
    members,
    membersInUse,
    setMembersInUse,
    setLeader,
    other
}) {
    return (
        <DatalistInput
            placeholder={placeholder}
            label={placeholder}
            onChange={(event) => {
                const search = event.target.value
                if (search.length === 0) {
                    setMembersInUse(members.filter((member) => !other.includes(member.id)))
                    return
                }

                if (search.length < 3 && search.length > 0) {
                    return
                }

                const filtered = members.filter((member) => {
                    const fullname = `${member.firstname} ${member.middlename} ${member.lastname}`
                    return fullname.toLowerCase().includes(search.toLowerCase())
                })

                setMembersInUse(filtered)
            }}
            onSelect={(item) => {
                setLeader(item.id)
                setMembersInUse(membersInUse.filter((member) => member.id !== item.id))
            }}
            items={membersInUse.map((member, index) => {
                return (
                    {
                        id: member.id,
                        label: `${member.firstname} ${member.middlename} ${member.lastname}`,
                        value: `${member.firstname} ${member.middlename} ${member.lastname}`,
                    }
                )
            })}
        />
    )
}

export default DataList