import React, { useEffect, useState } from 'react'
import Alert from '../Alert'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'

function ManageContributions() {
    const auth = useAuth()

    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [contributions, setContributions] = useState([])

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Date Created',
            selector: row => row.date_created,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: false,
        },
        {
            name: 'Action',
            cell: (row) => {
                return <button
                    className='btn btn-sm pcm-btn-primary me-2'
                    disabled={row.status === "DISCONTINUED"}
                    onClick={() => handleDiscontinue(row.id)}>
                    {
                        row.status === "DISCONTINUED" ? "Discontinued" : "Discontinue"
                    }
                </button>
            },
            sortable: false,
        }
    ]

    const handleDiscontinue = async (id) => {
        // ask for user confirmation
        const check = window.confirm("Are you sure you want to discontinue this contribution?")
        if (check != true) return

        setMessage("Loading...")
        setVariant("info")

        try {
            const response = await fetch(`${BASE_API_URL}/contribution/${id}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const res = await response.json()
            if (res.status === "failed") {
                setMessage(res.message)
                setVariant("warning")
                return
            }

            setMessage(res.message)
            setVariant("success")
            fetchContributions()
        }
        catch (error) {
            setMessage("Failed to discontinue contribution! Try again.")
            setVariant("danger")
        }
    }

    const fetchContributions = async () => {
        setMessage("Loading...")
        setVariant("info")

        try {
            const response = await fetch(`${BASE_API_URL}/contributions`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const res = await response.json()
            if (res.status === "failed") {
                setMessage(res.message)
                setVariant("warning")
                return
            }

            setMessage("")
            setContributions(res.contributions)
        }
        catch (error) {
            setMessage("Failed to fetch contributions! Try again.")
            setVariant("danger")
        }
    }

    useEffect(() => {
        fetchContributions()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3'>Manage Contributions</p>
            <div className='shadow rounded my-3 p-2'>
                {
                    message && <Alert variant={variant} message={message} />
                }
                <DataTable
                    columns={columns}
                    data={contributions}
                    highlightOnHover
                    pagination
                    paginationPerPage={20}
                    noDataComponent={"No Contributions Created"}
                />
            </div>
        </div>
    )
}

export default ManageContributions