import React, { useState } from 'react'
import Alert from '../Alert'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'

function NewMessages() {
    const auth = useAuth()

    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [isLoading, setisLoading] = useState(false)

    const [messageBody, setMessageBody] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setisLoading(true)
        setMessage('')

        try{
            const response = await fetch(`${BASE_API_URL}/chairs-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token,
                },
                body: JSON.stringify({ body: messageBody })
            })

            const responseData = await response.json()

            if (responseData.status === "success") {
                setMessage(responseData.message)
                setVariant('success')
                setisLoading(false)
            }
            else{
                setMessage(responseData.message)
                setVariant('warning')
                setisLoading(false)
            }
        }
        catch(error){
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
        finally{
            setisLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 mb-3'>New Daily Message</p>
            <div className='shadow rounded p-2'>
                {
                    message && <Alert variant={variant} message={message} />
                }
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='messageBody'>Message</label>
                                <textarea
                                    className='form-control'
                                    id='messageBody'
                                    placeholder='Type message here...'
                                    value={messageBody}
                                    onChange={(e) => setMessageBody(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <button type='submit' className='btn pcm-btn-primary px-4'>
                        {
                            isLoading ? "Posting..." : "Post Message"
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}

export default NewMessages
