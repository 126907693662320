import React from 'react'

function Alert({ variant, message}) {
  return (
    <div className={`alert alert-${variant} p-2 mx-1`}>
      { message }
    </div>
  )
}

export default Alert