import React, { useEffect, useState } from 'react'
import { BASE_API_URL } from '../utils/constants'
import { useAuth } from '../context/AuthContext'

function Dashboard() {
    const auth = useAuth()

    const [activeMembers, setActiveMembers] = useState(0)
    const [zones, setZones] = useState(0)
    const [branches, setBranches] = useState(0)
    const [dailyMessage, setDailyMessage] = useState('Give thanks for the Lord is good.')
    const [author, setAuthor] = useState('')

    const fetchData = async () =>{
        try{
            const response = await fetch(`${BASE_API_URL}/dashboard`,{
                method: "GET",
                headers: {
                    'x-access-token': auth.token,
                }
            })
            const data = await response.json()
            if (data.status === "success"){
                setActiveMembers(data.payload.active_members)
                setBranches(data.payload.branches)
                setZones(data.payload.zones)
                setDailyMessage(data.payload.word_of_the_day.body)
                setAuthor(data.payload.word_of_the_day.author)
            }
        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        fetchData()
    }, [])

    return (
        <div className='py-3'>
            <div className="card shadow rounded border-0">
                <div className="card-body">
                    <blockquote className="blockquote mb-0">
                        <p>{dailyMessage}</p>
                        <footer className="blockquote-footer mt-1">
                            CTF Chairperson <cite title="Vincent Laizer">{author}</cite>
                        </footer>
                    </blockquote>
                </div>
            </div>

            <div className='row my-4 mx-1'>
                <div className="card col-sm my-2 bg-primary mx-2">
                    <div className="card-body">
                        <h4 className="card-title">{activeMembers}</h4>
                        <p className="card-text">Registered Members</p>
                    </div>
                </div>
                <div className="card col-sm my-2 bg-secondary mx-2">
                    <div className="card-body">
                        <h4 className="card-title">{zones}</h4>
                        <p className="card-text">Zones</p>
                    </div>
                </div>
                <div className="card col-sm my-2 bg-accent mx-2">
                    <div className="card-body">
                        <h4 className="card-title">{branches}</h4>
                        <p className="card-text">Branches</p>
                    </div>
                </div>
            </div>

            <div className='row my-4 mx-1'>
                {/* charts and graphs section */}
            </div>
        </div>
    )
}

export default Dashboard
