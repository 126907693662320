import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext'
import Alert from '../components/Alert'
import { BASE_API_URL } from '../utils/constants'
import leaderImage from '../assets/images/leader.jpg'

function Profile() {
	const auth = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [firstName, setFirstName] = useState('')
	const [middleName, setMiddleName] = useState('')
	const [surname, setSurname] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [gender, setGender] = useState('')
	const [baptism, setBaptism] = useState('')
	const [residence, setResidence] = useState('')
	const [image, setImage] = useState('')
	const [yos, setYos] = useState(0)
	const [role, setRole] = useState('')
	const [level, setLevel] = useState('')

	const updateProfilePic = async (e) => {
		e.preventDefault()
		setMessage('')

		const formData = new FormData()
		formData.append('profile_picture', image)

		try {
			const response = await fetch(`${BASE_API_URL}/profile-pic`, {
				method: 'POST',
				headers: {
					'x-access-token': auth.token,
				},
				body: formData,
			})

			const data = await response.json()
			setMessage(data.message)
			setVariant('success')
		}
		catch (error) {
			setMessage("Something went wrong. Please try again.")
			setVariant('danger')
			console.log(error)
		}
	}

	const fetchProfileDetails = async () => {
		setIsLoading(true)

		try {
			const response = await fetch(`${BASE_API_URL}/profile`, {
				method: 'GET',
				headers: {
					'x-access-token': auth.token,
				},
			})

			const data = await response.json()
			setFirstName(data.user.firstname)
			setMiddleName(data.user.middlename)
			setSurname(data.user.lastname)
			setPhoneNumber(data.user.phone)
			setEmail(data.user.email)
			setGender(data.user.gender)
			setBaptism(data.user.baptism)
			setResidence(data.user.residence)
			setImage(data.user.image)
			setYos(data.user.yos)
			setRole(data.user.role)
			setLevel(data.user.level)
		}
		catch (error) {
			setMessage("Something went wrong. Please try again.")
			setVariant('danger')
		}
		setIsLoading(false)
	}

	useEffect(() => {
		fetchProfileDetails()
	}, [])

	return (
		<>
			<div className='pt-2'>
				<p className='h3'>Member Profile</p>
				<div className='shadow rounded p-2'>
					{
						isLoading && (
							<div className='text-center my-3'>
								Loading... <i className='bi bi-arrow-repeat'></i>
							</div>
						)
					}
					{
						message && <Alert variant={variant} message={message} />
					}
					<div className='my-3 text-center'>
						{
							image ? (
								<img
							src={`${BASE_API_URL}${image}`}
							alt='profile'
							className='rounded-circle'
							width='150'
							height='150'
						/>	
							):(
								<i className='bi bi-person-circle' style={{ fontSize: '150px' }}></i>
							)
						}
						<form className='mt-3 row text-center' onSubmit={updateProfilePic}>
							<div className='col col-lg-4 mb-2 ms-auto'>
								<input
									className='form-control'
									type='file'
									id='formFile'
									placeholder='Upload Image'
									onChange={(e) => setImage(e.target.files[0])}
									required
								/>
							</div>
							<div className='col col-lg-1 mb-3 me-auto'>
								<button type='submit' className='btn pcm-btn-primary px-4 fw-bold'>
									Upload
								</button>
							</div>
						</form>
					</div>
					<div className='row pt-3'>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='firstname' className='form-label'>First Name</label>
								<input
									type='text'
									id='firstname'
									className='form-control'
									placeholder='First Name'
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='middlename' className='form-label'>Middle Name</label>
								<input
									type='text'
									id='middlename'
									className='form-control'
									placeholder='Middle Name'
									value={middleName}
									onChange={(e) => setMiddleName(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='surname' className='form-label'>Surname</label>
								<input
									type='text'
									id='surname'
									className='form-control'
									placeholder='Surname'
									value={surname}
									onChange={(e) => setSurname(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='phone' className='form-label'>Phone Number</label>
								<input
									type='text'
									id='phone'
									className='form-control'
									placeholder='0759640777'
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='email' className='form-label'>Email</label>
								<input
									type='email'
									id='email'
									className='form-control'
									placeholder='tucasa@gmail.com'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='gender' className='form-label'>Gender</label>
								<select
									id='gender'
									className='form-select'
									value={gender}
									onChange={(e) => setGender(e.target.value)}
									disabled
								>
									<option value="">Select Gender</option>
									<option value="Female">Female</option>
									<option value="Male">Male</option>
								</select>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='baptism' className='form-label'>Baptism</label>
								<select
									className='form-select'
									id='baptism'
									value={baptism}
									onChange={(e) => setBaptism(e.target.value)}
									disabled
								>
									<option value="">Select Baptism Status</option>
									<option value="Baptised">Baptised</option>
									<option value="Not Baptised">Not Baptised</option>
								</select>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='residence' className='form-label'>Residence</label>
								<input
									type='text'
									id='residence'
									className='form-control'
									placeholder='5AT7'
									value={residence}
									onChange={(e) => setResidence(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='yos' className='form-label'>Year of Study</label>
								<input
									type='text'
									id='yos'
									className='form-control'
									placeholder='1'
									value={yos}
									onChange={(e) => setYos(e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='role' className='form-label'>Role</label>
								<select
									id='role'
									className='form-select'
									value={role}
									onChange={(e) => setRole(e.target.value)}
									disabled
								>
									<option value="">Select Role</option>
									<option value="ADMIN">ADMIN</option>
									<option value="CHAIRPERSON">Chairperson</option>
									<option value="SECRETARY">Secretary</option>
									<option value="TREASURER">Treasurer</option>
									<option value="MEMBER">Member</option>
								</select>
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label htmlFor='level' className='form-label'>Level</label>
								<select
									id='level'
									className='form-select'
									value={level}
									onChange={(e) => setLevel(e.target.value)}
									disabled
								>
									<option value="">Select Level</option>
									<option value="CTF">CTF</option>
									<option value="ZONE">Zone</option>
									<option value="BRANCH">Branch</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='my-4 pt-2'>
				<p className='h3'>Password Upadate</p>
				<div className='shadow rounded p-2'>
					<form className='row pt-3'>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label className='form-label' htmlFor='new_password'>Old Password</label>
								<input type='password' className='form-control' id='old_password' />
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label className='form-label' htmlFor='new_password'>New Password</label>
								<input type='password' className='form-control' id='new_password' />
							</div>
						</div>
						<div className='col-lg-5 mb-3'>
							<div className='form-group'>
								<label className='form-label' htmlFor='new_password'>Confirm Password</label>
								<input type='password' className='form-control' id='confirm_password' />
							</div>
						</div>
						<div className='mx-2 mb-3'>
							<button type='submit' className='btn pcm-btn-primary px-4 fw-bold'>Update</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Profile