import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext'
import Alert from '../components/Alert'
import { BASE_API_URL } from '../utils/constants'

function MyContributions() {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [contributions, setContributions] = useState([])

	const fetchContributions = async () => {
		setMessage("Loading...")
		setVariant("info")

		try {
			const results = await fetch(`${BASE_API_URL}/member-contributions`, {
				method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token,
                }
			})
			const data = await results.json()
			setContributions(data.contributions)
		}
		catch (e) {
			setMessage("An Error Occurred! Please Try Again Later.")
			setVariant("danger")
		}
		finally{
			setMessage("")
		}
	}

	useEffect(() => {
		fetchContributions()
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3 mb-3'>My Contributions</p>
			{
				message && <Alert message={message} variant={variant} />
			}
			{
				contributions && (
					contributions.length < 1 ? (
						<p className='text-center'>No Contributions Available</p>
					) : (
						contributions.map((c) =>
							<div className={`shadow rounded my-3 p-2 ${c.status === "DISCONTINUED" && "bg-secondary-light"}`}>
								{c.name} <span className='text-danger'>({c.status})</span>
								<div className="progress my-2">
									<div className="progress-bar" role="progressbar" style={{ width: `${c.percent}%` }} aria-valuenow={c.percent} aria-valuemin="0" aria-valuemax="100">
										{c.percent}%
									</div>
								</div>
								<div className='text-end'>
									<span>{c.amount_contributed}/{c.amount} Tsh</span>
									<button className='btn pcm-btn-primary btn-sm ms-3 fw-bold'>
										Contribution History
									</button>
								</div>
							</div>
						)
					)
				)
			}
		</div>
	)
}

export default MyContributions